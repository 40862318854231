<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12" md="12">
            <ValidationProvider name="Name" rules="required|min:2|max:255">
              <v-text-field
                v-model="userGroupLocal.name"
                :disabled="loading"
                label="User Group Name"
                :error-messages="errors"
                slot-scope="{ errors }"
                ref="title"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <ValidationProvider name="Permission">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="permissionMatrix"
            item-key="id"
            hide-default-footer
            disable-sort
            disable-pagination
            class="permission-table"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td @click.stop="onRowClick(item.id)">{{ item.title }}</td>
                  <td class="text-xs-center"><v-checkbox v-model="item.add"></v-checkbox></td>
                  <td class="text-xs-center"><v-checkbox v-model="item.edit"></v-checkbox></td>
                  <td class="text-xs-center"><v-checkbox v-model="item.delete"></v-checkbox></td>
                  <td class="text-xs-center"><v-checkbox v-model="item.view"></v-checkbox></td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </ValidationProvider>
        <v-btn @click="saveAction" :disabled="loading || invalid" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<style scope="local">
.v-data-table-header-mobile {
  display: none;
}

.permission-table .v-input {
  justify-content: center;
}
.permission-table .v-input__control {
  flex-grow: 0;
  width: 20px;
}
</style>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';

extend('required', required);
extend('max', max);
extend('min', min);

export default {
  name: 'UserGroupDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('userGroup', ['loading', 'userGroup', 'error', 'success', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    permissionMatrix: function () {
      if (this.userGroupLocal && this.userGroupLocal.permission_matrix) {
        return this.userGroupLocal.permission_matrix;
      }
      return [];
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save. This is where we
     * set the default values for new records
     */
    userGroupLocal: function () {
      return loadDash.cloneDeep(this.userGroup);
    }
  },
  methods: {
    /**
     * Set the focus on the first field if possible
     */
    focusFirstField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.title.focus();
        }, 100);
      });
    },
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        this.userGroupLocal.permissions = this.getPermissionArrayFromMatrix();
        store.dispatch('userGroup/save', {
          id: this.userGroupLocal.id,
          userGroup: this.userGroupLocal
        });
      }
      this.resetValidation();
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    /**
     * Get the permission array from the matrix
     *
     * @returns array
     */
    getPermissionArrayFromMatrix() {
      const permissions = [];
      this.permissionMatrix.forEach(function (item) {
        const title = item.title.toLowerCase();
        if (item.add) permissions.push('add ' + title);
        if (item.edit) permissions.push('edit ' + title);
        if (item.delete) permissions.push('delete ' + title);
        if (item.view) permissions.push('view ' + title);
      });
      return permissions;
    },
    /**
     * Handel row click
     *
     * @param id id of permission row
     */
    onRowClick(id) {
      const toggle = this.permissionMatrix[id].add ? false : true;
      this.permissionMatrix[id].add = toggle;
      this.permissionMatrix[id].edit = toggle;
      this.permissionMatrix[id].delete = toggle;
      this.permissionMatrix[id].view = toggle;
    },
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    }
  },
  data: () => ({
    headers: [
      {
        text: '',
        align: 'left',
        value: 'title'
      },
      {
        text: 'Add',
        align: 'center',
        value: 'add'
      },
      {
        text: 'Edit',
        align: 'center',
        value: 'edit'
      },
      {
        text: 'Delete',
        align: 'center',
        value: 'delete'
      },
      {
        text: 'View',
        align: 'center',
        value: 'view'
      }
    ],
    selected: []
  })
};
</script>
