import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{ref:"title",attrs:{"disabled":_vm.loading,"label":"User Group Name","error-messages":errors},model:{value:(_vm.userGroupLocal.name),callback:function ($$v) {_vm.$set(_vm.userGroupLocal, "name", $$v)},expression:"userGroupLocal.name"}})}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Permission"}},[_c(VDataTable,{staticClass:"permission-table",attrs:{"headers":_vm.headers,"items":_vm.permissionMatrix,"item-key":"id","hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{on:{"click":function($event){$event.stopPropagation();return _vm.onRowClick(item.id)}}},[_vm._v(_vm._s(item.title))]),_c('td',{staticClass:"text-xs-center"},[_c(VCheckbox,{model:{value:(item.add),callback:function ($$v) {_vm.$set(item, "add", $$v)},expression:"item.add"}})],1),_c('td',{staticClass:"text-xs-center"},[_c(VCheckbox,{model:{value:(item.edit),callback:function ($$v) {_vm.$set(item, "edit", $$v)},expression:"item.edit"}})],1),_c('td',{staticClass:"text-xs-center"},[_c(VCheckbox,{model:{value:(item.delete),callback:function ($$v) {_vm.$set(item, "delete", $$v)},expression:"item.delete"}})],1),_c('td',{staticClass:"text-xs-center"},[_c(VCheckbox,{model:{value:(item.view),callback:function ($$v) {_vm.$set(item, "view", $$v)},expression:"item.view"}})],1)])}),0)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid},on:{"click":_vm.saveAction}},[_vm._v("Save")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }