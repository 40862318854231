<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ userGroup.name || 'New User Group' }}</h1>
    <v-card>
      <v-card-text>
        <UserGroupDetailForm ref="userGroupDetailForm" />
      </v-card-text>
    </v-card>
    <ConfirmDialog :show="showNavigateAwayConfirmDialog" @confirm-dialog-clicked="confirmNavigate" />
  </v-container>
</template>

<script>
import store from '@/store/index';
import { mapGetters } from 'vuex';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import UserGroupDetailForm from '@/views/UserGroupDetailForm';

export default {
  name: 'GroupView',
  beforeRouteLeave(to, from, next) {
    if (this.$refs.userGroupDetailForm.isDirty()) {
      this.next = next;
      this.showNavigateAwayConfirmDialog = true;
    } else {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('userGroup/get', to.params.id).then(() => {
      next();
    });
  },
  components: {
    UserGroupDetailForm,
    ConfirmDialog
  },
  methods: {
    confirmNavigate: function (navigateAway) {
      this.showNavigateAwayConfirmDialog = false;
      if (navigateAway) {
        this.next();
      }
    }
  },
  computed: {
    ...mapGetters('userGroup', ['userGroup'])
  },
  data: () => ({
    tabs: null,
    next: null,
    showNavigateAwayConfirmDialog: false,
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'User Groups',
        exact: true,
        to: '/usergroups'
      },
      {
        text: 'User Group',
        disabled: true,
        exact: true,
        to: '/usergroups/:id'
      }
    ]
  })
};
</script>
